import { commonSelectMessages, TabItem } from 'azshared';
import React, { Fragment, MouseEvent, RefObject, useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import useEventListener from '../../hooks/use-event-listener';
import { AppCheckbox } from '../checkbox/app-checkbox';
import './app-select.scss';

interface InternalAppOption<T extends string> extends TabItem<T> {
  isSelected?: boolean;
}

interface AppSelectDropdownProps<T extends string> {
  value?: T | T[];
  multiple?: boolean;
  options: TabItem<T>[];
  refInput: RefObject<HTMLDivElement>;
  open: boolean;
  onChange?: (value: T | T[]) => void;
  onSelectAll?: () => void;
  onClose: () => void;
}

export const AppSelectDropdown = <T extends string>(props: AppSelectDropdownProps<T>) => {
  const { formatMessage } = useIntl();
  const { value, multiple, refInput, open, onChange = () => null, options, onSelectAll, onClose } = props;

  const refDropdown: RefObject<HTMLDivElement> = useRef(null);

  const handleChange = useCallback(
    (val: T) => {
      if (multiple) {
        if (!value) {
          onChange([val]);
        } else if (Array.isArray(value) && !value.includes(val)) {
          onChange([...value, val]);
        } else if (Array.isArray(value) && value.includes(val)) {
          onChange(value.filter(v => v !== val));
        }
      } else {
        onChange(val);
        onClose();
      }
    },
    [value, multiple, onChange, onClose]
  );

  const handleItemClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      handleChange((e.target as HTMLDivElement).id as T);
    },
    [handleChange]
  );

  const handleCheckboxChange = useCallback(
    (_: boolean, id?: string | undefined) => {
      handleChange(id as T);
    },
    [handleChange]
  );

  const internalOptions: InternalAppOption<T>[] = useMemo(() => {
    return options.map(option => ({ ...option, isSelected: multiple || Array.isArray(value) ? value?.includes(option.type) : value === option.type }));
  }, [multiple, options, value]);

  const handleClick = useCallback(
    (event: Event) => {
      if (refDropdown.current && !refDropdown.current.contains(event.target as Node) && !refInput?.current?.contains(event.target as Node)) {
        onClose();
      }
    },
    [refDropdown, refInput, onClose]
  );

  useEventListener('mousedown', handleClick);

  return (
    <>
      {open && (
        <div className='app-select-dropdown' ref={refDropdown}>
          {!multiple &&
            internalOptions?.map(item => (
              <div key={item.type} className={`app-select-option ${item.isSelected ? 'active' : ''}`} id={item.type} onClick={handleItemClick}>
                {item.label}
              </div>
            ))}
          {multiple && (
            <Fragment>
              <div className='app-select-option app-select-option-select-all' onClick={onSelectAll}>
                {formatMessage(commonSelectMessages.selectAll)}
              </div>
              {internalOptions?.map(item => (
                <div key={item.type} className={`app-multi-select-option ${item.isSelected ? 'active' : ''}`}>
                  <AppCheckbox label={item.label} id={item.type} onChange={handleCheckboxChange} checked={item.isSelected} />
                </div>
              ))}
            </Fragment>
          )}
        </div>
      )}
    </>
  );
};
