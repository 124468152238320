import {
  appRoutes,
  clearMultipleMatchesFlag,
  clearRetrieveUsernameError,
  commonButtonMessages,
  messagesForRetrieveUsernameError,
  PersonaTipoUtenteEnum,
  resetRetrieveUsernameState,
  retrieveUsername,
  retrieveUsernameMessages,
  RetrieveUsernameOptions,
  setSelectedOption,
  setUserEmail,
  setUserPhoneNumber,
  useAppSelector
} from 'azshared';
import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppButton, AppButtonType } from '../../../components/buttons/app-button';
import { Card } from '../../../components/card/card';
import { AppSuccessDialog } from '../../../components/dialog/app-success-dialog';
import { AppInputText } from '../../../components/input-text/app-input-text';
import { AppLink } from '../../../components/labels/app-link';
import { AppRadioButtonProps } from '../../../components/radio/app-radio-button';
import { AppRadioGroup } from '../../../components/radio/app-radio-group';
import { showAppToast } from '../../../components/toast/app-toast';
import { closeAppToast } from '../../../hooks/global-actions';
import { LoginContainer } from '../login-container/login-container';
import './username-recovery-page.scss';

const getSubtitle = (userType: PersonaTipoUtenteEnum) => userType === 'PF' ? retrieveUsernameMessages.pageSubtitlePF : retrieveUsernameMessages.pageSubtitle;

export const UserRecoveryPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const { retrieveUsernameRequestStatus, retrieveUsernameError, userEmail, userPhoneNumber, multipleMatchesFound, selectedOption, userType } = useAppSelector(
    state => state.features.retrieveUsername
  );

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const closeSuccessDialog = useCallback(() => {
    setOpenSuccessDialog(false);
    dispatch(resetRetrieveUsernameState());
    navigate(appRoutes.Login, { replace: true });
  }, [dispatch, navigate]);

  const updateEmail = useCallback(
    (value: string | undefined) => {
      dispatch(setUserEmail(value));
    },
    [dispatch]
  );
  const updatePhoneNumber = useCallback(
    (value: string | undefined) => {
      dispatch(setUserPhoneNumber(value));
    },
    [dispatch]
  );

  const onSelectedOptionChanged = useCallback((option: string) => dispatch(setSelectedOption(option as RetrieveUsernameOptions)), [dispatch]);

  const subtitle: string = useMemo(() => 
    formatMessage(getSubtitle(userType)), 
  [userType, formatMessage]);

  const retrieveUsernameOptions: AppRadioButtonProps[] = useMemo(() => [
    {
      value: RetrieveUsernameOptions.ByMail,
      label: formatMessage(retrieveUsernameMessages.labelFirstRadioButton)
    },
    {
      value: RetrieveUsernameOptions.BySMS,
      label: formatMessage(retrieveUsernameMessages.labelSecondRadioButton)
    }
  ], [ formatMessage ]);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      dispatch(retrieveUsername({}));
    },
    [dispatch]
  );

  useEffect(() => {
    if (retrieveUsernameRequestStatus === 'succeeded') {
      setOpenSuccessDialog(true);
    }
  }, [retrieveUsernameRequestStatus, navigate, dispatch, selectedOption]);

  useEffect(() => {
    if (multipleMatchesFound) {
      navigate(appRoutes.RetrieveUsernameMultipleMatch, { replace: false });
      dispatch(clearMultipleMatchesFlag());
      dispatch(clearRetrieveUsernameError());
    }
  }, [multipleMatchesFound, dispatch, navigate, onSelectedOptionChanged, selectedOption]);
  useEffect(() => {
    if (retrieveUsernameError) {
      const errorMessages = messagesForRetrieveUsernameError(retrieveUsernameError);
      showAppToast({
        type: 'error',
        title: formatMessage(errorMessages.title),
        description: errorMessages.description && formatMessage(errorMessages.description),
        close: closeAppToast
      });
      dispatch(clearRetrieveUsernameError());
    }
  }, [retrieveUsernameError, dispatch, formatMessage]);


  return (
    <LoginContainer>
      <div className='user-recovery'>
        <Card
          title={formatMessage(retrieveUsernameMessages.pageTitle)}
          subtitle={subtitle}
          back={formatMessage(commonButtonMessages.backLink)}
        >
          {userType !== 'PF' &&  <div className='radio-button'>
            <AppRadioGroup options={retrieveUsernameOptions} selectedValue={selectedOption} onSelectedValueChanged={onSelectedOptionChanged} />
          </div>}
          {userType === 'PF' &&  <div className='user-recovery-text-pf'>
            {formatMessage(retrieveUsernameMessages.textPF)}
          </div>}
          <form onSubmit={handleSubmit}>
            {selectedOption === RetrieveUsernameOptions.ByMail && (
              <div className='input-box'>
                <span className='description'>{formatMessage(retrieveUsernameMessages.labelEmailInputText)}</span>
                <AppInputText placeholder={formatMessage(retrieveUsernameMessages.placeholderEmailInputText)} value={userEmail || ''} onChange={updateEmail} />
              </div>
            )}
            {selectedOption === RetrieveUsernameOptions.BySMS && (
              <div className='input-box'>
                <span className='description'>{formatMessage(retrieveUsernameMessages.labelPhoneNumberInputText)}</span>
                <AppInputText
                  placeholder={formatMessage(retrieveUsernameMessages.placeholderPhoneNumberInputText)}
                  value={userPhoneNumber || ''}
                  onChange={updatePhoneNumber}
                />
              </div>
            )}
            <div className='button'>
              <AppButton
                type={AppButtonType.SUBMIT}
                label={formatMessage(retrieveUsernameMessages.retrieveUsernameButton)}
                loading={retrieveUsernameRequestStatus === 'loading'}
              />
            </div>
          </form>
          <span className='disclaimer'>
            {formatMessage(retrieveUsernameMessages.disclaimer)}
            <AppLink to={appRoutes.Login} label={formatMessage(commonButtonMessages.linkToLogin)} />
          </span>
        </Card>
      </div>
      <AppSuccessDialog
        open={openSuccessDialog}
        onClose={closeSuccessDialog}
        buttonLabel={formatMessage(retrieveUsernameMessages.successDialogRetrieveUsernameButtonLabel)}
      >
        <div className='text-dialog'>
          {selectedOption === RetrieveUsernameOptions.ByMail && formatMessage(retrieveUsernameMessages.successMessageRetrieveUsernameByEmail)}
          {selectedOption === RetrieveUsernameOptions.BySMS && formatMessage(retrieveUsernameMessages.successMessageRetrieveUsernameBySMS)}
        </div>
      </AppSuccessDialog>
    </LoginContainer>
  );
};
