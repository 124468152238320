import { Checkbox, FormControlLabel } from '@mui/material';
import React, { ChangeEvent, useCallback } from 'react';
import './app-checkbox.scss';

export interface AppCheckboxProps {
	label?: string | number | React.ReactElement;
	disabled?: boolean;
	indeterminate?: boolean;
	checked?: boolean;
	id?: string;
	onChange?: (value: boolean, id?: string) => void;
}

export const AppCheckbox = (props: AppCheckboxProps) => {
	const { id, label, disabled = false, indeterminate = false, checked = false, onChange } = props;

	const handleChange = useCallback(
		(_: ChangeEvent<HTMLInputElement>, value: boolean) => {
			if (onChange) {
				onChange(value, id);
			}
		},
		[id, onChange]
	);

	return (
		<FormControlLabel
			className={'app-checkbox'}
			control={
				<Checkbox disabled={disabled} checked={checked} indeterminate={indeterminate} onChange={handleChange} />
			}
			label={label || ''}
		/>
	);
};
