import React, { useCallback } from 'react';
import { mdiEyeOutline } from '@mdi/js';
import { AppButton } from '../../../../components/buttons/app-button';
import { appRoutes, HomeCard, homeDocumentsCardMessages } from 'azshared';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { AppSvgIcon } from '../../../../components/svg-icon/app-svg-icon';

import './home-documents-card.scss';
import { Loader, LoaderSize } from '../../../../components/loader/loader';

export const HomeDocumentsCard = (props: { totalDocuments: HomeCard<number>}) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { totalDocuments } = props;

  const goToDocuments = useCallback(() => {
    navigate(appRoutes.Documents);
  }, [navigate]);

  return (
    <div className='home-documents-card'>
      <div className='home-documents-card-header'>
        <AppSvgIcon icon='home-documents' />
        <h4> {formatMessage(homeDocumentsCardMessages.cardTitle)} </h4>
      </div>
      {(totalDocuments.requestStatus !== 'loading' && !!totalDocuments.value && totalDocuments.value > 0) && (
        <>
          <div className='home-documents-card-description'>
            {formatMessage(homeDocumentsCardMessages.descriptionDocumentsCardFirstPart)}
            <span>{totalDocuments.value}</span>
            {formatMessage(homeDocumentsCardMessages.descriptionDocumentsCardSecondPart)}
          </div>
          <div className='home-documents-card-button'>
            <AppButton label={formatMessage(homeDocumentsCardMessages.buttonLabelDocumentsCard)} iconLeft={mdiEyeOutline} onClick={goToDocuments} />
          </div>
        </>
      )}
      {(totalDocuments.requestStatus !== 'loading' && totalDocuments.value === 0) && (
        <div className='home-documents-card-no-documents'>
          <div className='home-documents-card-no-documents-description'>{formatMessage(homeDocumentsCardMessages.descriptionDocumentsCardNoDocuments)}</div>
          <div className='img-no-documents' />
        </div>
      )}
      {totalDocuments.requestStatus === 'loading' &&  <div className='home-documents-card-loader'> <Loader loadersize={LoaderSize.XL}/> </div>}
    </div>
  );
};
