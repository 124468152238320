//import {mdiOpenInNew} from '@mdi/js';
import {
  documentsAreaCommonSliceActions,
  documentsAreaMessages,
  useAppDispatch,
  useAzimutLifeLogic,
  useDocumentsAreaLogic,
  useDocumentsDownloadFileLogic
} from 'azshared';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {AppErrorDialog} from '../../../components/dialog/app-error-dialog';
import {AppHeader} from '../../../components/header/header';
import {AppTabBar} from '../../../components/tab-bar/tab-bar';
import {useGlobalActions} from '../../../hooks/global-actions';
import {downloadFile} from '../../../utils/utils';
import {CommunicationsSection} from './communications/communications-section/communications-section';
import './documents-area-page.scss';
import {DocumentsSection} from './documents-section/documents-section';
import {InformationOfferCard} from './information-offer/information-offer-card/information-offer-card';
//import {AppButton} from '../../../components/buttons/app-button';

export const DocumentsAreaPage = () => {
  const { formatMessage } = useIntl();

  const { sections, selectedSection, onSelectedSection, onMarkDocumentAsRead } = useDocumentsAreaLogic();

  const { fileDownloaded, onDownloadFile } = useDocumentsDownloadFileLogic(useGlobalActions());
  const dispatch = useAppDispatch();

  const { azlifeHome, /*getAzimutLifeAccessToken*/ clear, isRequestError } = useAzimutLifeLogic();

  useEffect(() => {
    if (fileDownloaded) {
      downloadFile(fileDownloaded.name, fileDownloaded.blob);
      dispatch(documentsAreaCommonSliceActions.clearDownloadFile());
    }
  }, [ fileDownloaded, dispatch ]);

  if(azlifeHome){
    return <iframe src={azlifeHome} className='azlife-iframe' />;
  }

  return (
    <div className='documents-area-page'>
      <AppHeader />
      <h2>{formatMessage(documentsAreaMessages.title)}</h2>
      <div className='tab-section'>
        <AppTabBar tabs={sections} onTabSelected={onSelectedSection} value={selectedSection} />
        {/*<AppButton label={formatMessage(documentsAreaMessages.azimutLifeLabel)} iconLeft={mdiOpenInNew} autocase={true} onClick={getAzimutLifeAccessToken} />*/}
      </div>
      {selectedSection === 'DOCUMENTS' && <DocumentsSection onReadDocument={onMarkDocumentAsRead} onDownloadDocument={onDownloadFile}/>}
      {selectedSection === 'INFO_OFFER' && <InformationOfferCard onReadDocument={onMarkDocumentAsRead} onDownloadDocument={onDownloadFile}/>}
      {selectedSection === 'COMMUNICATIONS' && <CommunicationsSection onReadDocument={onMarkDocumentAsRead} onDownloadDocument={onDownloadFile}/>}

      <AppErrorDialog open={isRequestError} onClose={clear}>
        {formatMessage(documentsAreaMessages.azimutLifeBodyError)}
      </AppErrorDialog>
    </div>
  );
};
