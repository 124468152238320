import React, { ChangeEvent, useCallback } from 'react';
import { AppRadioButton, AppRadioButtonConfig } from './app-radio-button';
import './app-radio-button.scss';

export interface AppRadioGroupProps {
  selectedValue?: string;
  options: AppRadioButtonConfig[];
  onSelectedValueChanged: (value: string) => void;
  horizontal?: boolean;
  label?: string;
}

export const AppRadioGroup = (props: AppRadioGroupProps) => {
  const { selectedValue, options, onSelectedValueChanged, horizontal, label } = props;

  const onSelect = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onSelectedValueChanged(e.target.value);
    },
    [onSelectedValueChanged]
  );

  return (
    <div className={`app-radio-group ${horizontal ? 'horizontal-radio-button-group' : ''}`}>
      {label && <label className='app-radio-group-title'>{label}</label>}
      {options.map((option, index) => (
        <AppRadioButton key={index} checked={selectedValue === option.value} onSelect={onSelect} {...option} />
      ))}
    </div>
  );
};
