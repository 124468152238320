import Icon from '@mdi/react';
import React, { ChangeEvent, FormEvent, MouseEvent, useCallback } from 'react';
import { AppCustomIcons } from '../../utils/custom-icons';
import { AppSvgIcon } from '../svg-icon/app-svg-icon';
import './app-input-text.scss';

export type InputTextDirectionType = 'horizontal' | 'vertical';
export interface AppInputTextProps {
  type?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  icon?: string;
  svgIcon?: AppCustomIcons;
  errorMessage?: string;
  required?: boolean;
  value?: string;
  arrangement?: InputTextDirectionType;
  onChange?: (value: string | undefined, e: ChangeEvent<HTMLInputElement>) => void;
  onIconClick?: (a: MouseEvent<HTMLButtonElement> | MouseEvent<SVGSVGElement>) => void;
  onPaste?: (e: FormEvent) => void;
  onCopy?: (e: FormEvent) => void
}

export const AppInputText = (props: AppInputTextProps) => {
  const {
    type = 'text',
    label,
    placeholder,
    disabled,
    errorMessage = '',
    icon,
    required,
    value,
    svgIcon,
    arrangement = 'vertical',
    onChange,
    onIconClick,
    onCopy, 
    onPaste
  } = props;

  const onTextChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.value || undefined, e);
      }
    },
    [onChange]
  );

  return (
    <div className={`input-text ${arrangement === 'horizontal' ? ' horizontal' : ''}`}>
      {label && (
        <label>
          {label}
          {required ? '*' : ''}
        </label>
      )}
      <div className='input-wrapper'>
        <input className={errorMessage && 'input-error'} type={type} placeholder={placeholder} disabled={disabled} value={value} onChange={onTextChanged} onPaste={onPaste} onCopy={onCopy}/>
        {icon && (
          <button type='button' className='icon' onClick={onIconClick}>
            <Icon path={icon} size={1} color='#C4C4C4' />
          </button>
        )}
        {svgIcon && <AppSvgIcon icon={svgIcon} onClick={onIconClick} />}
      </div>
      {errorMessage && (
        <div className='message'>
          <span className='error-message'>{errorMessage}</span>
        </div>
      )}
    </div>
  );
};
