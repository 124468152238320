import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';
import { Collapse, IconButton } from '@mui/material';
import { labelForWalletCategoryType, ProductCategory, ProductMacroCategory, walletPageMessages } from 'azshared';
import React, { MouseEvent, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppSvgIcon } from '../../../../../components/svg-icon/app-svg-icon';
import { FinancialPositionCategoryTable } from '../financial-positions-category-table/financial-positions-category-table';

interface FinancialPositionsCategoryBoxProps {
  category: ProductCategory;
  macroCategory: ProductMacroCategory;
  onGoToPrevinet: () => void;
}

export const FinancialPositionsCategoryBox = (props: FinancialPositionsCategoryBoxProps) => {
  const { category, macroCategory, onGoToPrevinet } = props;
  const [open, setOpen] = useState(false);

  const { formatMessage } = useIntl();

  const toggleOpen = useCallback(() => setOpen(!open), [open]);

  const handlePrevinetClick = useCallback((e: MouseEvent<HTMLAnchorElement | SVGSVGElement>) => {
    e.preventDefault();
    onGoToPrevinet();
  }, [onGoToPrevinet]);

  return (
    <div className='financial-positions-box'>
      <div className='financial-positions-accordion'>
        {formatMessage(labelForWalletCategoryType(category.type))}
        {macroCategory.type === 'Welfare' && <div className='previnet-link'>
          <AppSvgIcon icon='link' onClick={handlePrevinetClick}></AppSvgIcon>
          <a onClick={handlePrevinetClick}>{formatMessage(walletPageMessages.previnetLinkLabel)}</a>
          </div>}
        <IconButton aria-label='expand row' size='small' onClick={toggleOpen}>
          <Icon size={1.5} path={open ? mdiChevronUp : mdiChevronDown} color={'#2E6CD1'} />
        </IconButton>
      </div>
      <div className={open ? 'is-expansion-visible' : ''}>
        <Collapse in={open} timeout='auto' unmountOnExit className='financial-positions-accordion-box'>
          <FinancialPositionCategoryTable macroCategoryType={macroCategory?.type} productCategory={category}/>
        </Collapse>
      </div>
    </div>
  );
};
