import React, { ChangeEvent } from 'react';
import './app-radio-button.scss';

export interface AppRadioButtonConfig {
  value: string;
  label: string;
  disabled?: boolean;
}

export interface AppRadioButtonProps extends AppRadioButtonConfig {
  checked?: boolean;
  onSelect?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const AppRadioButton = (props: AppRadioButtonProps) => {
  const { label, value, checked = false, disabled = false, onSelect } = props;

  return (
    <label className={`container ${disabled ? ' disabled' : ''}`}>
      <input type='radio' value={value} checked={checked} disabled={disabled} onChange={onSelect} />
      <span className='checkmark' />
      {label}
    </label>
  );
};
