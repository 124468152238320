import { AZIMUT_URL, signatureAreaMessages, SignatureDocument, SignatureDocumentModule } from 'azshared';
import React, { Fragment, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { SignatureAreaBaseDocumentModule } from './signature-area-base-document-module';

export const SignatureAreaBaseDocumentContent = (props: {
  document: SignatureDocument;
  isLoadingRejectProposal: boolean;
	isLoadingGetUrlForSignature: boolean;
  openDetail: (moduleId: string, documentId: string) => void;
  startProcedure: (documentId: string) => void;
  rejectProposal: (documentId: string) => void;
}) => {
  const { document, openDetail, startProcedure, rejectProposal, isLoadingGetUrlForSignature, isLoadingRejectProposal } = props;
  const [isSecondStep, setIsSecondStep] = useState(false);
  const [checked, setChecked] = useState(false);
  const { formatMessage } = useIntl();

  const handleClickForward = useCallback(() => {
    if (document.type?.practiceType === 'InvestmentProposal' && document.type?.proposalType === 'Advanced' && !isSecondStep) {
      setIsSecondStep(true);
      setChecked(false);
    } else {
      startProcedure(document.id);
    }
  }, [document, isSecondStep, startProcedure]);

  const handleClickRejected = useCallback(() => {
    rejectProposal(document.id);
  }, [document.id, rejectProposal]);

  const handleClickGoBack = useCallback(() => {
    setIsSecondStep(false);
    setChecked(true);
  }, [setIsSecondStep, setChecked]);

  const handleAcknowledgementChanged = useCallback(
    (value: boolean) => {
      setChecked(value);
    },
    [setChecked]
  );

  return (
    <Fragment>
      {document?.modules?.map((item: SignatureDocumentModule, index) => (
        <SignatureAreaBaseDocumentModule
          key={item.title}
          isFirstModule={index === 0}
          document={document}
          module={item}
          openDetail={openDetail}
          clickGoBack={handleClickGoBack}
          clickGoForward={handleClickForward}
          clickRejected={handleClickRejected}
          isSecondStep={isSecondStep}
          acknowledgementChecked={checked}
          onAcknowledgementChanged={handleAcknowledgementChanged}
          isLoadingRejectProposal={isLoadingRejectProposal}
          isLoadingGetUrlForSignature={isLoadingGetUrlForSignature}
        />
      ))}
      {document.type?.practiceType === 'InvestmentProposal' && (
        <div className='signature-area-rto-disclaimer-footer'>
          {formatMessage(signatureAreaMessages.textFooterDisclaimer)}
          <a href={AZIMUT_URL} target='_blank' rel='noreferrer'>
            {formatMessage(signatureAreaMessages.linkFooterDisclaimer)}{' '}
          </a>
        </div>
      )}
    </Fragment>
  );
};
