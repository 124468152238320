import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Table from '@mui/material/Table/Table';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { productPerformanceColumns, ProductTransactionsList, transactionsColumns } from 'azshared';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Loader, LoaderSize } from '../../../../../components/loader/loader';
import { AppTableCell } from '../../../../../components/table/table-cell';
import { AppTableCellHeader } from '../../../../../components/table/table-cell-header';
import { PageSelector } from '../../../../admin-console/customer-service/results/page-selector/page-selector';
import './transactions-table.scss';

interface TransactionsTableProps {
  transactionList: ProductTransactionsList;
  isLoading: boolean;
  onTransactionsPaginationPrev: () => void;
  onTransactionsPaginationNext: () => void;
  onTransactionsPaginationChange: (n: number) => void;
}

export const TransactionsTable = (props: TransactionsTableProps) => {
  const { transactionList, isLoading, onTransactionsPaginationChange, onTransactionsPaginationNext, onTransactionsPaginationPrev } = props;
  const { formatMessage } = useIntl();

  return (
    <div className='transactions'>
      <TableContainer className='transactions-table'>
        <Table aria-label='table'>
          <TableHead>
            <TableRow>
              {transactionsColumns.map((column, index) => (
                <AppTableCellHeader key={index} className='transactions-table-header'>
                  {formatMessage(column.headerLabel)}
                </AppTableCellHeader>
              ))}
            </TableRow>
          </TableHead>
          {isLoading && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={productPerformanceColumns.length + 1}>
                  <div className='table-loader'>
                    <Loader loadersize={LoaderSize.XL} />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!isLoading && (
            <TableBody>
              {transactionList?.transactions.map((transaction, index) => (
                <TableRow key={index} className='transactions-table-row'>
                  {transactionsColumns.map(cell => (
                    <Fragment key={cell.key}>
                      {cell.key === 'reason' && (
                        <AppTableCell key={cell.key} align='left' type={cell.type} className='performance-table-cell-description'>
                          {transaction[cell.key]}
                        </AppTableCell>
                      )}
                      {cell.key !== 'reason' && (
                        <AppTableCell key={cell.key} align='left' type={cell.type}>
                          {transaction[cell.key]}
                        </AppTableCell>
                      )}
                    </Fragment>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {transactionList?.pageInfo.count > transactionList?.pageInfo.perPage && (
        <div className='transactions-paginator'>
          <PageSelector
            pagination={transactionList?.pageInfo}
            showPreviousPage={onTransactionsPaginationPrev}
            showNextPage={onTransactionsPaginationNext}
            showPage={onTransactionsPaginationChange}
          />
        </div>
      )}
    </div>
  );
};
