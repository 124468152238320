import { AppToast, GlobalActions, resetUserRegistrationState } from 'azshared';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { showAppToast } from '../components/toast/app-toast';
import webStorage from '../data/web-storage';

export const useGoToLoginFromRegistration = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(resetUserRegistrationState()), [dispatch]);
};

export const closeAppToast = () => {
  return toast.dismiss();
};

export const useGlobalActions: () => 
GlobalActions  = () => {
  return {
    navigate: useNavigate(),
    showToast: (appToast: AppToast) =>
      showAppToast({
        type: appToast.type,
        title: appToast.title,
        description: appToast.description,
        close: appToast.close
      }),
    closeToast: closeAppToast,
    getStorage: () => webStorage
  };
};
