import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import {
  changePasswordMessages,
  commonButtonMessages,
  getStatusUtenza,
  profilePageMessages,
  timelineProfileMessages,
  useAppDispatch,
  useAppSelector,
  useChangeEmailDialogLogic,
  useChangePassword,
  useChangePhoneNumberDialogLogic,
  useChangeSecondaryPhoneNumberDialogLogic,
  UserState,
  useSignatureAreaLogic
} from 'azshared';
import React, { FormEvent, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType } from '../../../components/buttons/app-button';
import { Card, CardSize } from '../../../components/card/card';
import { AppSuccessDialog } from '../../../components/dialog/app-success-dialog';
import { AppHeader } from '../../../components/header/header';
import { AppInputPassword } from '../../../components/input-text/app-input-password';
import { Loader, LoaderSize } from '../../../components/loader/loader';
import { AppRequiredNote } from '../../../components/required-note/required-note';
import { AppSvgIcon } from '../../../components/svg-icon/app-svg-icon';
import { useGlobalActions } from '../../../hooks/global-actions';
import { ChangeEmailDialogs } from './change-email-dialogs';
import { ChangePhoneNumberDialogs } from './change-phone-number-dialogs';
import { ChangeSecondaryPhoneNumberDialogs } from './change-secondary-phone-number-dialogs';
import './profile-page.scss';

export const ProfilePage = () => {
  const { formatMessage } = useIntl();

  const globalActions = useGlobalActions();
  const dispatch = useAppDispatch();

  const {
    oldPassword,
    newPassword,
    confirmNewPassword,
    changePasswordRequestStatus,
    changePasswordForm,
    openSuccessDialog,
    handleCloseSuccessDialog,
    onChangePasswordForm,
    setConfirmNewPassword,
    setNewPassword,
    setOldPassword,
    handleSubmit
  } = useChangePassword(globalActions);

  useEffect(() => {
    dispatch(getStatusUtenza());
  }, [dispatch]);

  const { goToSignatureAreaFromProfile } = useSignatureAreaLogic(globalActions);

  const { onChangeEmail } = useChangeEmailDialogLogic(globalActions);
  const { onChangePhoneNumber } = useChangePhoneNumberDialogLogic(globalActions);
  const { handleEditPhoneNumber } = useChangeSecondaryPhoneNumberDialogLogic(globalActions);

  const { signFilesEmail, signFilesPhoneNumber, name, surname, userCode, email, phone, secondaryPhone, signFilesRequestStatus, userType } = useAppSelector<UserState>((state) => state.data.user);

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  const steps = [formatMessage(timelineProfileMessages.step1), formatMessage(timelineProfileMessages.step2), formatMessage(timelineProfileMessages.step3)];

  return (
    <div className='profile-page'>
      <AppHeader />
      <div className='card-profile'>
        <Card title={formatMessage(profilePageMessages.pageTitle)} back={formatMessage(commonButtonMessages.backLink)} sizeVariant={CardSize.MAXI}>
          { signFilesRequestStatus === 'loading' && 
            <div className='profile-page-loader'>
              <Loader loadersize={LoaderSize.L}></Loader>
            </div>
          }
          { signFilesRequestStatus !== 'loading' && 
            <>
              <div className='user'>
                <svg className='avatar' />
                <span>
                  {name} {surname}
                </span>
              </div>
              <div className='fields'>
                <div className='field first-column'>
                  <div className='title'>{formatMessage(profilePageMessages.labelIdentificationNumberField)}</div>
                  <div className='description'>{userCode}</div>
                </div>

                <div className='field first-column'>
                  {/* Change Email */}
                  <div>
                    <div className={`title ${signFilesEmail ? 'red-title' : ''}`}>
                      {formatMessage(signFilesEmail ? profilePageMessages.labelEmailChangedField : profilePageMessages.labelEmailField)}
                      {!signFilesEmail && (
                        <span onClick={onChangeEmail}>
                          <AppSvgIcon icon='pencil' />
                        </span>
                      )}
                    </div>
                    <div className='description'>{email}</div>
                  </div>
                  <ChangeEmailDialogs steps={steps} />
                </div>

                <div className='field first-column'>
                  {/* Change Phone Number */}
                  <div>
                    <div className={`title ${signFilesPhoneNumber ? ' red-title' : ''}`}>
                      {formatMessage(signFilesPhoneNumber ? profilePageMessages.labelPhoneNumberChangedField : profilePageMessages.labelPhoneNumberField)}
                      {!signFilesPhoneNumber && (
                        <span onClick={onChangePhoneNumber}>
                          <AppSvgIcon icon='pencil' />
                        </span>
                      )}
                    </div>
                    <div className='description'>{phone}</div>
                  </div>
                  <ChangePhoneNumberDialogs steps={steps} />
                </div>

                {userType === 'PF' &&<div className='field first-column'>
                  <div className='title'>
                    {formatMessage(profilePageMessages.labelSecondaryPhoneNumberField)}
                    <AppSvgIcon icon='pencil' onClick={handleEditPhoneNumber}/>
                  </div>
                  <div className='description'>{secondaryPhone}</div>
                  <ChangeSecondaryPhoneNumberDialogs />
                </div>}

                {(signFilesEmail || signFilesPhoneNumber) && (
                  <div className='sign-button'>
                    <AppButton label={formatMessage(profilePageMessages.labelSignUpdateButton)} onClick={goToSignatureAreaFromProfile} />
                  </div>
                )}

                <div className='field'>
                  <div className='title'>
                    {formatMessage(profilePageMessages.labelPasswordField)}
                    {!changePasswordForm && (
                      <span onClick={onChangePasswordForm}>
                        <AppSvgIcon icon='pencil' />
                      </span>
                    )}
                    {changePasswordForm && (
                      <span onClick={onChangePasswordForm}>
                        <Icon className='icon' size={1} path={mdiClose} />{' '}
                      </span>
                    )}
                  </div>
                  {!changePasswordForm && <div className='description'>*******************</div>}
                </div>

                {changePasswordForm && (
                  <form className='form-change-password' onSubmit={onSubmit}>
                    <div>
                      <AppInputPassword
                        placeholder={formatMessage(changePasswordMessages.oldPasswordLabel)}
                        label={formatMessage(changePasswordMessages.oldPasswordPlaceholder)}
                        required
                        value={oldPassword}
                        onChange={setOldPassword}
                      />
                      <AppInputPassword
                        placeholder={formatMessage(changePasswordMessages.newPasswordLabel)}
                        label={formatMessage(changePasswordMessages.newPasswordPlaceholder)}
                        required
                        value={newPassword}
                        onChange={setNewPassword}
                      />
                      <AppInputPassword
                        placeholder={formatMessage(changePasswordMessages.confirmPasswordLabel)}
                        label={formatMessage(changePasswordMessages.confirmPasswordPlaceholder)}
                        required
                        value={confirmNewPassword}
                        onChange={setConfirmNewPassword}
                      />
                    </div>

                    <AppRequiredNote />

                    <div className='button confirm-button'>
                      <AppButton
                        type={AppButtonType.SUBMIT}
                        label={formatMessage(changePasswordMessages.changePasswordConfirmButton)}
                        loading={changePasswordRequestStatus === 'loading'}
                      />
                    </div>
                  </form>
                )}
              </div>
            </>
          }
          
        </Card>
      </div>

      {/* Success Dialog Change Password */}
      <AppSuccessDialog open={openSuccessDialog} onClose={handleCloseSuccessDialog}>
        <div className='text-dialog'>{formatMessage(changePasswordMessages.messageChangePasswordSuccess)}</div>
      </AppSuccessDialog>
    </div>
  );
};
