import { PersonaTipoUtenteEnum, userRegistrationMessages, useUserRegistrationPageLogic } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppButton } from '../../../components/buttons/app-button';
import { Card } from '../../../components/card/card';
import { AppRadioButtonProps } from '../../../components/radio/app-radio-button';
import { AppRadioGroup } from '../../../components/radio/app-radio-group';
import { useGlobalActions } from '../../../hooks/global-actions';
import { LoginContainer } from '../login-container/login-container';
import './user-registration-page.scss';

export const UserRegistrationPage = () => {
  const { formatMessage } = useIntl();

  const { selectedOptionUserType, goAhead, onSelectedOptionChanged } = useUserRegistrationPageLogic(useGlobalActions());

  const userRegistrationOptions: AppRadioButtonProps[] = [
    {
      value: PersonaTipoUtenteEnum.Pf,
      label: formatMessage(userRegistrationMessages.labelNaturalPersonRadioButton)
    },
    {
      value: PersonaTipoUtenteEnum.Pg,
      label: formatMessage(userRegistrationMessages.labelLegalPersonRadioButton)
    }
  ];

  return (
    <LoginContainer>
      <div className='user-registration'>
        <Card
          title={formatMessage(userRegistrationMessages.pageTitle)}
          subtitle={formatMessage(userRegistrationMessages.pageSubtitle)}
          back={formatMessage(userRegistrationMessages.backTo)}
        >
          <div className='radio-button'>
            <AppRadioGroup options={userRegistrationOptions} selectedValue={selectedOptionUserType} onSelectedValueChanged={onSelectedOptionChanged} />
          </div>
          <div className='button'>
            <AppButton label={formatMessage(userRegistrationMessages.buttonUserRegistration)} onClick={goAhead} />
          </div>
        </Card>
      </div>
    </LoginContainer>
  );
};
