import { profilePageMessages } from 'azshared';
import { PersonaTipoEnum } from 'azshared/build/main/lib/api/generated';
import React from 'react';
import { useIntl } from 'react-intl';
import './menu-header.scss';

export const AppMenuHeader = (props: { name: string; surname: string; onProfileIconPress: () => void; businessName: string; userType: string}) => {
	const { formatMessage } = useIntl();
	return (
		<div className='app-menu-header' onClick={props.onProfileIconPress}>
			<svg className='app-menu-header-avatar' />
			<div className='app-menu-header-description'>
				<span className='app-menu-header-name'>
					{props.userType === PersonaTipoEnum.Pf && `${props.name} ${props.surname}`}
					{props.userType === PersonaTipoEnum.Pg && props.businessName}
				</span>
				{props.userType === PersonaTipoEnum.Pg && <span>{`${props.name} ${props.surname}`}</span>}
				<div className='app-menu-header-my-azimut'>{formatMessage(profilePageMessages.disclaimerMyAzimut)}</div>
			</div>
		</div>
	);
};
