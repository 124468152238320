import { MARKETPLACE_KEY, env } from 'azshared';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, LoaderSize } from '../../components/loader/loader';
import { useGlobalActions } from '../../hooks/global-actions';

const IosMarketplace = () => {
    const {token} = useParams();
    const { getStorage } = useGlobalActions();

    useEffect(() => {
        if (token) {
          const date = new Date();
          date.setTime(date.getTime() + 5 * 60 * 1000);
          getStorage().setCookieOnDomain(
            MARKETPLACE_KEY,
            token,
            env.baseDomain,
            date
          ).then(() => window.open(env.marketplaceUrl));
        }
    }, [getStorage, token]);

    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
        }}
      >
        <Loader loadersize={LoaderSize.XL}></Loader>
      </div>
    );
};

export default IosMarketplace;