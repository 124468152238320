import { env, ProductMacroCategory, usePrevinetLogic, walletPageMessages } from 'azshared';
import React, { Fragment, RefObject, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { AppErrorDialog } from '../../../../../components/dialog/app-error-dialog';
import { FinancialPositionsMacroCategoryItem } from './financial-position-macrocategory-item';

interface FinancialPositionsCardProps {
	macroCategories?: ProductMacroCategory[];
	flagShowBalance: boolean;
	goToRealEconomyDetailPage: () => void;
}

export const FinancialPositionsCard = (props: FinancialPositionsCardProps) => {
	const formRef: RefObject<HTMLFormElement> = useRef(null);
	const { macroCategories, flagShowBalance, goToRealEconomyDetailPage } = props;
	const { formatMessage } = useIntl();

	const {getPrevinetToken, previnetToken, clear, isRequestError} = usePrevinetLogic();

	useEffect(() => {
		if (previnetToken !== undefined) {
			formRef?.current?.submit();
			clear();
		}

	}, [clear, previnetToken]);

	return (
		<Fragment>
			<div className='financial-positions-card'>
				{
					macroCategories?.map((macroCategory, index) => (
						<div key={index}>
						<FinancialPositionsMacroCategoryItem
							key={index}
							macroCategory={macroCategory}
							flagShowBalance={flagShowBalance}
							onGoToPrevinet={getPrevinetToken}
							goToRealEconomyDetailPage={goToRealEconomyDetailPage}
						/>
					</div>
				))}
			</div>
			<form ref={formRef} action={env.previnetUrl} method='POST' encType='text/plain' style={{display: 'none'}} target='_blank'>
				<input name={`{"access_token": "${previnetToken}"}`}/>
			</form>
			<AppErrorDialog open={isRequestError} onClose={clear}>{formatMessage(walletPageMessages.errorDescriptionAccessPrevinet)}</AppErrorDialog>
		</Fragment>
	);
};
