import { dashboardMessages, useAppSelector, useDashboardPageLogic, UserState } from 'azshared';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppHeader } from '../../../components/header/header';
import { useGlobalActions } from '../../../hooks/global-actions';
import { HomeAzimutForYouCard } from './home-azimut-for-you-card/home-azimut-for-you-card';
import { HomeDocumentsCard } from './home-documents-card/home-documents-card';
import { HomeFinancialConsultantCard } from './home-financial-advisor-card/home-financial-consultant-card';
import { HomeMarketingAreaCard } from './home-marketing-area-card/home-marketing-area-card';
import { HomeNewsCard } from './home-news-card/home-news-card';
import './home-page.scss';
import { HomeSignatureAreaCard } from './home-signature-area-card/home-signature-area-card';
import { HomeWalletCard } from './home-wallet-card/home-wallet-card';


export const HomePage = () => {
  const globalActions = useGlobalActions();
  const { formatMessage } = useIntl();
  const { documents, signature, news, financialAdvisor, video, sections, selectedSection, onSelectedSection, myWallet, setFlagShowBalance, sharedAccounts, dossierRequestStatus } =
    useDashboardPageLogic(globalActions);

  const { informativeMode } = useAppSelector<UserState>((state) => state.data.user);

  return (
    <div className='home-page'>
      <AppHeader />
      <h2>{formatMessage(dashboardMessages.walletCardTitle)}</h2>
      <div className={`home-page-content${selectedSection === 'PIR_BOX' ? '-pir' :''}`}>
        <div className='home-page-wallet-section'>
          {/* Wallet section */}
          <HomeWalletCard
            dossierRequestStatus={dossierRequestStatus}
            myWalletHomeCard={myWallet} 
            sharedAccounts={sharedAccounts}
            sections={sections}
            selectedSection={selectedSection}
            onSelectedSection={onSelectedSection}
            setFlagShowBalance={setFlagShowBalance}
          />
        </div>
        {selectedSection !== 'PIR_BOX' && (
          <>
            <div className='home-page-second-column'>
              {/* card: signature, azimut and news */}
              {!informativeMode && <HomeSignatureAreaCard documents={signature} />}
              <HomeMarketingAreaCard />
              <HomeNewsCard news={news} />
            </div>
            <div className='home-page-third-column'>
              {/* card: document, financial consultant, azimut for you */}
              {!informativeMode && <HomeDocumentsCard totalDocuments={documents} />}
              <HomeFinancialConsultantCard consultant={financialAdvisor} />
              <HomeAzimutForYouCard video={video} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
