import { mdiChevronLeft } from '@mdi/js';
import { Icon } from '@mdi/react';
import { commonButtonMessages, signatureAreaMessages, SignatureDocument, SignatureDocumentModule, SignatureDocumentServiceActivationModule } from 'azshared';
import React, { Fragment, useCallback} from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonVariant } from '../../../../../components/buttons/app-button';
import { AppCheckbox } from '../../../../../components/checkbox/app-checkbox';
import { AppSvgIcon } from '../../../../../components/svg-icon/app-svg-icon';
import { SignatureAreaLink } from '../signature-area-link/signature-area-link';
import './signature-area-base-document-module.scss';

export const SignatureAreaBaseDocumentModule = (props: {
  document: SignatureDocument;
  module: SignatureDocumentModule;
  isFirstModule: boolean;
  isSecondStep: boolean;
  acknowledgementChecked: boolean;
  isLoadingRejectProposal: boolean;
	isLoadingGetUrlForSignature: boolean;
  openDetail: (moduleId: string, documentId: string) => void;
  clickGoBack: () => void;
  clickGoForward: () => void;
  clickRejected: () => void;
  onAcknowledgementChanged: (checked: boolean) => void;
}) => {
  const {
    document,
    openDetail,
    module,
    isFirstModule,
    isSecondStep,
    clickGoBack,
    clickGoForward,
    clickRejected,
    onAcknowledgementChanged,
    acknowledgementChecked,
    isLoadingGetUrlForSignature,
    isLoadingRejectProposal
  } = props;
  const { formatMessage } = useIntl();

  const handleClick = useCallback(
    (moduleId: string) => {
      openDetail(moduleId, document.id);
    },
    [document.id, openDetail]
  );

  const onInfoClick = useCallback(() => {
    handleClick(module.id);
  }, [module.id, handleClick]);

  

  return (
    <Fragment>
      <div className='signature-area-base-document-module'>
        <div className='signature-area-base-document-module-title'>
          {!isFirstModule && <span>-</span>}
          <SignatureAreaLink module={module}/>
          {(module as SignatureDocumentServiceActivationModule).moduleInformation?.length > 0 && (
            <AppSvgIcon key={`icon-${module.id}`} icon='info' onClick={onInfoClick} />
          )}
        </div>
        {isFirstModule && module.type !== 'QuestionnaireModule' && (
          <div className='signature-area-base-document-module-disclaimer'>
            {formatMessage(isSecondStep ? signatureAreaMessages.maxFundDisclaimer : signatureAreaMessages.basicConsultingDisclaimer)}
          </div>
        )}
        {isFirstModule && (
          <div className='signature-area-base-document-module-buttons'>
            {module.type !== 'QuestionnaireModule' && (
              <AppCheckbox
                label={formatMessage(signatureAreaMessages.acknowledgmentLabel)}
                checked={!!document.nextRequiredSignature || acknowledgementChecked}
                disabled={!!document.nextRequiredSignature || isLoadingGetUrlForSignature}
                onChange={onAcknowledgementChanged}
              />
            )}
            {isSecondStep && (
              <a className='back' onClick={clickGoBack}>
                <Icon path={mdiChevronLeft} size={0.8} />
                <span>{formatMessage(commonButtonMessages.backLink)}</span>
              </a>
            )}
            {document.type?.practiceType === 'InvestmentProposal' && document.type?.proposalType === 'Advanced' && (
              <AppButton
                onClick={clickRejected}
                variant={AppButtonVariant.Secondary}
                disabled={!!document.nextRequiredSignature || isLoadingGetUrlForSignature}
                label={formatMessage(signatureAreaMessages.rejectLabel)}
              />
            )}
            <AppButton
              label={formatMessage(document.nextRequiredSignature ? signatureAreaMessages.signedLabel : signatureAreaMessages.forwardLabel)}
              disabled={!!document.nextRequiredSignature || (!acknowledgementChecked && module.type !== 'QuestionnaireModule') || isLoadingRejectProposal}
              onClick={clickGoForward}
              loading={isLoadingGetUrlForSignature}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};
