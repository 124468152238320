import { changePhoneNumberMessages, useChangeSecondaryPhoneNumberDialogLogic } from 'azshared';
import React, { FormEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppButton, AppButtonType } from '../../../components/buttons/app-button';
import { AppDialog } from '../../../components/dialog/app-dialog';
import { AppInputText } from '../../../components/input-text/app-input-text';
import { useGlobalActions } from '../../../hooks/global-actions';

export const ChangeSecondaryPhoneNumberDialogs = () => {
  const globalActions = useGlobalActions();
  const { formatMessage } = useIntl();

  const { openDialogChangePhoneNumber, closeChangePhoneNumber,
    buttonDisabled,
    handleSubmitInsertPhoneNumber,
    phoneNumber,
    updatePhoneNumber,
    confirmNewPhoneNumber,
    updateConfirmNewPhoneNumberChangePhoneNumber,
    insertPhoneNumberChangeSecondaryPhoneNumberRequestStatus
  } = useChangeSecondaryPhoneNumberDialogLogic(globalActions);

  const onSubmitInsertPhoneNumber = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      handleSubmitInsertPhoneNumber();
    },
    [handleSubmitInsertPhoneNumber]
  );

  const onCopy = useCallback((e: FormEvent) => {
    e.preventDefault();
  }, []);

  const onPaste = useCallback((e: FormEvent) => {
    e.preventDefault();
  }, []);

  return (
    <AppDialog open={openDialogChangePhoneNumber} onClose={closeChangePhoneNumber}>
      <div className='dialog-change-secondary-phone-number'>
        <div>
          <form className='form-dialog' onSubmit={onSubmitInsertPhoneNumber}>
            <div className='step-1'>
              <div className='phone-number-input-text'>
                <AppInputText
                  placeholder={formatMessage(changePhoneNumberMessages.newPhoneNumberlPlaceholder)}
                  value={phoneNumber}
                  label={formatMessage(changePhoneNumberMessages.newPhoneNumberlLabel)}
                  onChange={updatePhoneNumber}
                  onCopy={onCopy}
                  onPaste={onPaste}
                />
                <AppInputText
                  placeholder={formatMessage(changePhoneNumberMessages.confirmNewPhoneNumberlPlaceholder)}
                  value={confirmNewPhoneNumber}
                  label={formatMessage(changePhoneNumberMessages.confirmNewPhoneNumberlLabel)}
                  onChange={updateConfirmNewPhoneNumberChangePhoneNumber}
                  onCopy={onCopy}
                  onPaste={onPaste}
                />
              </div>
              <div className='button-dialog'>
                <AppButton
                  type={AppButtonType.SUBMIT}
                  label={formatMessage(changePhoneNumberMessages.changePhoneNumberGoOnButton)}
                  loading={insertPhoneNumberChangeSecondaryPhoneNumberRequestStatus === 'loading'}
                  disabled={buttonDisabled}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </AppDialog>
  );
};
