import { RangeDate } from 'azshared';
import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useEventListener from '../../hooks/use-event-listener';
import AppDateDropdown, { DropdownDirectionType } from '../date-dropdown/app-date-dropdown';
import { AppDateSelectInnerText } from './app-date-select-inner-text';
import './app-select.scss';

export interface AppDateSelectProps {
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	errorMessage?: string;
	required?: boolean;
	value: RangeDate;
	arrangement?: DropdownDirectionType;
  onChange: (value: RangeDate) => void;
  isValid?: (value: RangeDate) => boolean;
}

export const AppDateSelect = (props: AppDateSelectProps) => {
	const {
		label,
		placeholder,
		disabled,
		errorMessage = '',
		required,
		value,
		arrangement = 'vertical',
		onChange,
		isValid = () => true
	} = props;
	const [ isOpen, setIsOpen ] = useState(false);
	const [ rangeDate, setRangeDate ] = useState<RangeDate>({
    from: undefined,
    to: undefined
  });

	const refDropdown: RefObject<HTMLDivElement> = useRef(null);
	const refInput: RefObject<HTMLDivElement> = useRef(null);

  const enableApplyButton = useMemo(() => isValid(rangeDate), [ rangeDate, isValid ]);
  
  useEffect(() => setRangeDate(value), [value]);

	const handleDateChange = useCallback(
		(values: Date | [Date, Date]) => {
			if (Array.isArray(values)) {
				setRangeDate({
					from: values[0],
					to: values[1]
				});
			}
		},
		[ setRangeDate ]
	);

	const toggle = useCallback(
		() => {
			if (!disabled) {
				setIsOpen(!isOpen);
			}
		},
		[ disabled, isOpen ]
	);


  const handleSubmit = useCallback(
		() => {
			toggle();
			onChange(rangeDate);
		},
		[ rangeDate, onChange, toggle ]
	);

	const handleClick = useCallback(
		(event: Event) => {
			if (
				refDropdown.current &&
				!refDropdown.current.contains(event.target as Node) &&
				!refInput?.current?.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		},
		[ refDropdown, refInput ]
	);

	useEventListener('mousedown', handleClick);

	return (
		<div className='app-select'>
			{label && (
				<label>
					{label}
					{required ? '*' : ''}
				</label>
			)}
			<div
				ref={refInput}
				tabIndex={0}
				className={`app-select-input ${errorMessage ? 'app-select-error' : ''} ${disabled
					? 'app-select-disabled'
					: ''} ${!value ? 'app-select-placeholder' : ''}`}
				onClick={toggle}
			>
				<AppDateSelectInnerText value={value} placeholder={placeholder}/>
			</div>
			{isOpen && (
				<AppDateDropdown
					value={rangeDate}
					arrangement={arrangement}
					ref={refDropdown}
					onDateChange={handleDateChange}
					onSubmit={handleSubmit}
					enableApplyButton={enableApplyButton}
				/>
			)}
			{errorMessage && (
				<div className='message'>
					<span className='error-message'>{errorMessage}</span>
				</div>
			)}
		</div>
	);
};
