import React, { MouseEvent, useCallback } from 'react';
import { AppCustomIcons } from '../../utils/custom-icons';
import './app-svg-icon.scss';

export interface AppSvgIconProps {
	id?: string;
	icon: AppCustomIcons;
	onClick?: (e: MouseEvent<SVGSVGElement>, id?: string) => void;
}

export const AppSvgIcon = (props: AppSvgIconProps) => {
	const handleClick = useCallback((e: MouseEvent<SVGSVGElement>) => {
		props.onClick && props.onClick(e, props.id);
	}, [props]);

	return (
		<svg
			id={props.id}
			className={`app-svg-icon ${props.icon} ${props.onClick ? 'app-svg-icon-pointer' : ''}`}
			onClick={handleClick}
		/>
	);
};
