import { appRoutes, commonButtonMessages, PersonaTipoUtenteEnum, setSelectedUserType, useAppSelector, userTypeRecoveryMessages } from 'azshared';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppButton } from '../../../components/buttons/app-button';
import { Card } from '../../../components/card/card';
import { AppLink } from '../../../components/labels/app-link';
import { AppRadioButtonProps } from '../../../components/radio/app-radio-button';
import { AppRadioGroup } from '../../../components/radio/app-radio-group';
import { LoginContainer } from '../login-container/login-container';
import './user-type-recovery.scss';

export const UserTypeRecoveryPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userType } = useAppSelector(
    state => state.features.retrieveUsername
  );

  const userRegistrationOptions: AppRadioButtonProps[] = [
    {
      value: PersonaTipoUtenteEnum.Pf,
      label: formatMessage(userTypeRecoveryMessages.labelNaturalPersonRadioButton)
    },
    {
      value: PersonaTipoUtenteEnum.Pg,
      label: formatMessage(userTypeRecoveryMessages.labelLegalPersonRadioButton)
    }
  ];

  const goAhead = useCallback(() => {
    navigate(appRoutes.RetrieveUsername);
  }, [navigate]);

  const onSelectedValueChanged = useCallback((value: string) => {
    dispatch(setSelectedUserType(value as PersonaTipoUtenteEnum));
  }, [dispatch]);

  return (
    <LoginContainer>
      <div className='user-type-recovery'>
        <Card
          title={formatMessage(userTypeRecoveryMessages.pageTitle)}
          subtitle={formatMessage(userTypeRecoveryMessages.pageSubtitle)}
          back={formatMessage(commonButtonMessages.backLink)}
        >
          <div className='radio-button'>
            <AppRadioGroup options={userRegistrationOptions} selectedValue={userType} onSelectedValueChanged={onSelectedValueChanged} />
          </div>
          <div className='button'>
            <AppButton label={formatMessage(commonButtonMessages.confirm)} onClick={goAhead} />
          </div>
          <span className='disclaimer'>
            {formatMessage(userTypeRecoveryMessages.disclaimer)}
            <AppLink to={appRoutes.Login} label={formatMessage(commonButtonMessages.linkToLogin)} />
          </span>
        </Card>
      </div>
    </LoginContainer>
  );
};
